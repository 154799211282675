export default {
  translations: {
    About: "le Pavillon de Musique",
    Programmes: "Programmas",
    Recordings: "Opnames",
    Media: "Media",
    Contact: "Contact",
    ContactText: "we horen graag van u",
    Agenda: "Agenda",
    RecordingInfo: "Vioolconcerti H.-J. De Croes. Binnenkort!",
    FormText: "Schrijf in op onze nieuwsbrief",
    FormName: "Naam en voornaam ",
    FormEmail: "Email ",
    PrivacyAgreed1: "Ik ga akoord met de ",
    PrivacyAgreed2: "privacy policy (GDPR)",
    FormButton: "Inschrijven",
    Close: "sluiten",
    MoreInfo: "Meer info",
    Tickets: "Tickets",
  },
};
