import React, { useEffect, useRef, useState } from "react";
import { useYouTubePlayer } from "../../context/YoutubeContext";
import "./YoutubeStyles.scss";
import { FaPlayCircle, FaPauseCircle } from "react-icons/fa";

const CustomYouTubePlayer = ({ videoId, title }) => {
  const playerRef = useRef(null);
  const iframeRef = useRef(null);
  const { activePlayer, registerPlayer } = useYouTubePlayer();
  const [playing, setPlaying] = useState(false);

  const intervalRef = useRef(null);

  useEffect(() => {
    const createPlayer = () => {
      if (window.YT && window.YT.Player) {
        playerRef.current = new window.YT.Player(iframeRef.current, {
          videoId,
          playerVars: { controls: 0, modestbranding: 1 },
          events: {
            onReady: (event) => console.log(event.target.getDuration()),
            onStateChange: handleStateChange,
          },
        });
      }
    };

    if (window.YT && window.YT.Player) {
      createPlayer();
    } else {
      if (!document.querySelector("#youtube-iframe-api")) {
        const script = document.createElement("script");
        script.id = "youtube-iframe-api";
        script.src = "https://www.youtube.com/iframe_api";
        script.async = true;
        document.body.appendChild(script);
      }

      const checkYTReady = setInterval(() => {
        if (window.YT && window.YT.Player) {
          clearInterval(checkYTReady);
          createPlayer();
        }
      }, 100);
    }

    return () => {
      clearInterval(intervalRef.current);
    };
  }, [videoId]);

  // Handle play/pause events
  const handleStateChange = (event) => {
    if (event.data === window.YT.PlayerState.PLAYING) {
      registerPlayer(playerRef.current); // Mark this as the active player
      setPlaying(true);
    } else {
      setPlaying(false);
      clearInterval(intervalRef.current);
    }
  };

  // Play/Pause Handler
  const handlePlayPause = () => {
    if (playerRef.current) {
      if (playing) {
        playerRef.current.pauseVideo();
      } else {
        if (activePlayer && activePlayer !== playerRef.current) {
          activePlayer.pauseVideo(); // Stop other players
        }
        playerRef.current.playVideo();
      }
    }
  };

  return (
    <div className='youtube'>
      <div className='youtube__video'>
        <div ref={iframeRef}></div>
        {!playing && (
          <div className='youtube__overlay'>
            <span className='youtube__title'>{title}</span>
          </div>
        )}
        <button onClick={handlePlayPause} className='youtube__button'>
          {playing ? <FaPauseCircle size={44} /> : <FaPlayCircle size={44} />}
        </button>
      </div>
    </div>
  );
};

export default CustomYouTubePlayer;
