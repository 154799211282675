import React, { useState, useEffect } from "react";
import Contact from "./views/Contact/Contact";
import Posts from "./components/Posts";
import Footer from "./components/Footer";
import Toolbar from "./components/Toolbar/Toolbar";
import SideDrawer from "./components/SideDrawer/SideDrawer";
import Backdrop from "./components/Backdrop/Backdrop";
import SinglePost from "./components/SinglePost";
import HomeOverlay from "./components/HomeOverlay/HomeOverlay";

import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Projects from "./components/Programmes/Programmes";
import Ann from "./components/Ann/Ann";
import ProgrammeDetails from "./views/ProgrammeDetails/ProgrammeDetails";
import "./globalStyles.scss";
import Media from "./views/Media/Media";
import LPM from "./views/LPM/LPM";
import Recordings from "./views/Recordings/Recordings";
import RecordingDetails from "./views/RecordingDetails/RecordingDetails";
import Agenda from "./views/Agenda/Agenda";

const App = () => {
  const [sideDrawerOpen, setSideDrawerOpen] = useState(false);
  const [showOverlay, setShowOverlay] = useState(true);

  const drawerToggleClickHandler = () => {
    setSideDrawerOpen(!sideDrawerOpen);
  };

  const backdropClickHandler = () => {
    setSideDrawerOpen(false);
  };

  useEffect(() => {
    const switchedLang = window.localStorage.getItem("lang");
    if (!switchedLang) {
      window.localStorage.setItem("lang", "nl-NL");
    }
  }, []);

  useEffect(() => {
    const overlayClosed = window.sessionStorage.getItem("overlay");
    console.log(overlayClosed);
    setShowOverlay(!overlayClosed);
  }, []);

  const closeOverlay = () => {
    window.sessionStorage.setItem("overlay", "closed");
    setShowOverlay(false);
  };

  return (
    <Router>
      {showOverlay && <HomeOverlay show={showOverlay} onClose={closeOverlay} />}
      <div style={{ height: "100%" }}>
        <Toolbar drawerClickHandler={drawerToggleClickHandler} />
        <SideDrawer show={sideDrawerOpen} click={drawerToggleClickHandler} />
        {sideDrawerOpen && <Backdrop click={backdropClickHandler} />}
        <main style={{ marginTop: "56px" }}></main>{" "}
        <Switch>
          <Route exact path='/' component={LPM} />
          <Route path='/media' component={Media} />
          <Route path='/agenda' component={Agenda} />
          <Route path='/contact' component={Contact} />
          <Route path='/posts/:id' component={SinglePost} />
          {/* <Route path='/agenda' component={Posts} /> */}
          <Route path='/recordings/:title' component={RecordingDetails} />
          <Route path='/recordings' component={Recordings} />
          <Route path='/biography' component={LPM} />
          <Route path='/ann_cnop' component={Ann} />
          <Route path='/programmes/:navLink' component={ProgrammeDetails} />
          <Route path='/programmes' component={Projects} />
        </Switch>
        <Footer />
      </div>
    </Router>
  );
};

export default App;
