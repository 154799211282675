import React, { createContext, useContext, useState } from "react";

const YouTubePlayerContext = createContext();

export const YouTubePlayerProvider = ({ children }) => {
  const [activePlayer, setActivePlayer] = useState(null);

  const registerPlayer = (player) => {
    setActivePlayer(player);
  };

  return (
    <YouTubePlayerContext.Provider value={{ activePlayer, registerPlayer }}>
      {children}
    </YouTubePlayerContext.Provider>
  );
};

export const useYouTubePlayer = () => useContext(YouTubePlayerContext);
