import React, { useEffect, useState } from "react";
import { FaEnvelopeOpen, FaPhoneAlt, FaGlobe } from "react-icons/fa";
import Translator from "../../components/i18n/Translator";
import TitleBar from "../../components/TitleBar";
import group from "../../assets/imgs/Group.jpg";
import Privacy from "../../components/Privacy";
import logo from "../../assets/logo.jpg";
import agentLogo from "../../assets/imgs/ClaraMusica.jpg";
import Socials from "../../components/Socials/Socials";
import "./ContactStyles.scss";

const Contact = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  });

  const [modalOpen, setModalOpen] = useState(false);

  return (
    <div className='view'>
      <TitleBar title={<Translator path='Contact' />} />
      <div className='contact__group-foto'>
        <img src={group} alt='' />
      </div>
      <div className='contact__details text-center'>
        <div>
          <div>
            <img style={{ height: "100px" }} src={logo} alt='' />
          </div>
          <a href='mailto:info@lepavillondemusique.be'>
            <div style={{ marginBottom: "15px" }}>
              <FaEnvelopeOpen size={44} />
              <br></br>
            </div>

            <Translator path='ContactText' />
            <p>info@lepavillondemusique.be</p>
          </a>
          <div style={{ paddingBottom: "15px" }}>
            <FaPhoneAlt /> +32 477 53 97 48
          </div>
        </div>
        <div className='contact__agent-container'>
          <div>
            <img style={{ height: "150px" }} src={agentLogo} alt='' />
          </div>
          <p>
            <b>Booking Agent</b>
          </p>
          <p>CLARAMUSICA ARTIST MANAGEMENT </p>
          <p>Jean-Marc Poncelet - Directeur</p>
          <div style={{ paddingBottom: "15px" }}>
            <div>
              <FaPhoneAlt /> +32 478 34 82 85
            </div>
            <div>
              <FaGlobe />{" "}
              <a target='_blank' href='https://www.claramusica.com'>
                www.claramusica.com
              </a>
            </div>
          </div>
        </div>

        <Privacy openModal={modalOpen} closeModal={() => setModalOpen(false)} />
      </div>
      <div className='contact__socials'>
        <Socials />
      </div>
    </div>
  );
};

export default Contact;
