import React, { useState, useEffect } from "react";
import marked from "marked";
import { getAboutPageEng, getAboutPageFr, getAboutPageNl } from "../../contentful";
import useContentful from "../../hooks/useContentful";
import "./AnnStyles.scss";
import portrait from "../../assets/imgs/ann.jpg";

const Ann = () => {
  const [content, setContent] = useState();

  const contentEng = useContentful(getAboutPageEng)?.content[0]?.fields;
  const contentFr = useContentful(getAboutPageFr)?.content[0]?.fields;
  const contentNl = useContentful(getAboutPageNl)?.content[0]?.fields;
  const switchedLang = window.localStorage.getItem("lang") || "nl-NL";

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    setContent(switchedLang === "en-US" ? contentEng : switchedLang === "nl-NL" ? contentNl : contentFr);
  }, [contentEng, contentFr, contentNl, switchedLang]);

  return (
    <div className='view biog-page__container'>
      <div className='page-content  biog-page'>
        <div className='biog-page__image'>
          <img src={portrait} />
        </div>

        <div className='biog-page__text'>
          <h1>Ann Cnop</h1>
          {content?.text2 && <section dangerouslySetInnerHTML={{ __html: marked(content?.text2) }} />}
        </div>
      </div>
    </div>
  );
};

export default Ann;
