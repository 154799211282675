import React, { useState, useRef, useEffect } from "react";
import "./HomeOverlayStyles.scss";
import logo from "../../assets/logo.jpg";
import video from "../../assets/LPDM_short.webm";

const Home = ({ onClose }) => {
  const [leaving, setLeaving] = useState(false);
  const videoRef = useRef();
  const handleEnter = () => {
    setLeaving(true);
  };

  const handleTransitionEnd = () => {
    if (leaving) {
      onClose();
    }
  };

  useEffect(() => {
    if (videoRef.current) videoRef.current.play();
  }, [videoRef]);

  return (
    <div onTransitionEnd={handleTransitionEnd} className={!leaving ? "overlay" : "overlay overlay--leaving"}>
      <div className='overlay__logo'>
        <img src={logo} alt='logo' />
        <button className='overlay__button' onClick={handleEnter}>
          ENTER
        </button>
      </div>

      <video ref={videoRef} className='overlay__video' playsInline loop muted>
        <source
          src='https://res.cloudinary.com/dxoxy3nu9/video/upload/v1743059491/LPDM_short_jjcdyi.mp4'
          // src={video}
          type='video/mp4'
        />
      </video>
      <div className='overlay__video__overlay' />
    </div>
  );
};
export default Home;
