import React from "react";
import "./RecordingCardStyles.scss";
import { Link } from "react-router-dom";
import Translator from "../i18n/Translator";

const RecordingCard = ({ recording }) => {
  return (
    <div className='recording-card'>
      <div className='recording-card__text'>
        <h3 className='recording-card__title'>{recording.title}</h3>
        <div>
          <Link to={`/recordings/${recording.title}`} className='link-cta'>
            <Translator path='More' />
          </Link>
        </div>
      </div>
      <div className='recording-card__image'>
        <img alt='cd cover' src={recording.cover.fields.file.url} />
      </div>
    </div>
  );
};

export default RecordingCard;
