import React, { useEffect, useState } from "react";
import "./RecordingDetailsStyles.scss";
import { useParams } from "react-router-dom";
import { getRecording } from "../../contentful";
import Grid from "../../components/Grid/Grid";
import Youtube from "../../components/Youtube/Youtube";
import marked from "marked";

const RecordingDetails = () => {
  const [recording, setRecording] = useState({});
  const [text, setText] = useState("");
  const { title } = useParams();
  var switchedLang = window.localStorage.getItem("lang") || "nl-NL";

  useEffect(() => {
    getRecording(title).then((data) => setRecording(data[0].fields));
  }, [title]);

  useEffect(() => {
    if (switchedLang === "en-US") setText(recording.textEn);
    if (switchedLang === "fr-FR") setText(recording.textFr);
    if (switchedLang === "nl-NL") setText(recording.textNl);
  }, [switchedLang, recording]);
  console.log(recording);
  return (
    <div className='view recording-details '>
      {recording.cover && (
        <div className='recording-details__cover'>
          {" "}
          <img src={recording.cover.fields.file.url} />
        </div>
      )}
      {recording.title && <h1 className='recording-details__title'>{recording.title}</h1>}
      {recording.mp3 && (
        <div className='recording-details__audio-container'>
          {recording.mp3.map((mp3) => (
            <div className='recording-details__audio-item'>
              <small>{mp3.fields.title}</small>
              <audio controls>
                <source src={mp3.fields.file.url} />
              </audio>
            </div>
          ))}
        </div>
      )}

      {text && <section className='recording-details__text' dangerouslySetInnerHTML={{ __html: marked(text) }} />}
      <Grid columns={2}>
        {recording.youtubeVideos &&
          recording.youtubeVideos.map((v) => (
            <Youtube key={v.fields.id} title={v.fields.title} videoId={v.fields.id} />
          ))}
      </Grid>
    </div>
  );
};

export default RecordingDetails;
