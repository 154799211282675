import React from "react";
import { FaFacebook, FaSpotify } from "react-icons/fa";
import "./SocialsStyles.scss";
const Socials = () => {
  return (
    <div className='socials'>
      <a href='https://www.facebook.com/lepavillondemusique/' target='_blank'>
        <FaFacebook className='socials__icon' size={44} />
      </a>
      <a href='https://open.spotify.com/artist/5EjxLRUIqH1ufxMxMzB7ER?si=W2ALOJn2SVe2g6Oco1JYwQ' target='_blank'>
        <FaSpotify className='socials__icon' size={44} />
      </a>
    </div>
  );
};

export default Socials;
