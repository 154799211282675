import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./ProgrammesStyles.scss";
import Translator from "../../components/i18n/Translator";

const ProgrammeItem = ({ programme }) => {
  const [title, setTitle] = useState("");

  var switchedLang = window.localStorage.getItem("lang");

  const getTitle = (lang) => {
    switch (lang) {
      case "en-US":
        return programme.title;
      case "fr-FR":
        return programme.titleFr;
      case "nl-NL":
        return programme.titleNl;
      default:
        return programme.title;
    }
  };

  useEffect(() => {
    setTitle(getTitle(switchedLang));
  }, [switchedLang]);

  console.log(title);

  return (
    <div className='programme__item'>
      <div className='programme__item__left'>
        <h3 className='programme__item__title'>{title}</h3>
        <Link className='programme__item__link link-cta ' to={`/programmes/${programme.navigationLink}`}>
          <Translator path='MoreInfo' />
        </Link>
      </div>
      <div className='programme__item__image'>
        <img src={programme.mainImage.fields.file.url} />
      </div>
    </div>
  );
};

export default ProgrammeItem;
