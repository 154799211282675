export default {
  translations: {
    About: "le Pavillon de Musique",
    Programmes: "Programmes",
    Recordings: "Engregistrements",
    Media: "Média",
    Contact: "Contact",
    ContactText: "Nous aimerions avoir de vos nouvelles",
    Agenda: "Agenda",
    RecordingInfo: "Concertos pour violon H.-J. De Croes. Bientôt!",

    FormText: "Abonnez-vous à notre newsletter",
    FormName: "Nom et prénom",
    FormEmail: "Email",
    PrivacyAgreed1: "Je suis d'accord avec la",
    PrivacyAgreed2: " politique ser la protection de la vie privée (RGDP)",
    FormButton: "s'inscrire",
    Privacy: "Click here to read our privacy policy",
    Close: "Fermer",
    MoreInfo: "En savoir plus",
    Tickets: "Tickets",
  },
};
