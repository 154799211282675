import React from "react";
import { Link } from "react-router-dom";
import Translator from "../i18n/Translator";
import I18n from "../i18n/i18n";
import logo from "../../assets/logo.jpg";
import MechelenLogo from "../../assets/imgs/Mechelen_logo.svg";

import "./SideDrawer.scss";
import Socials from "../Socials/Socials";

export const routes = [
  { label: "About", route: "/biography" },
  { label: "Ann Cnop", route: "/ann_cnop" },
  { label: "Agenda", route: "/agenda" },
  { label: "Programmes", route: "/programmes" },
  { label: "Recordings", route: "/recordings" },
  { label: "Media", route: "/media" },
  { label: "Contact", route: "/contact" },
];

const sideDrawer = (props) => {
  let drawerClasses = "side-drawer";
  if (props.show) {
    drawerClasses = "side-drawer open";
  }

  return (
    <div>
      <nav className={drawerClasses}>
        <div className='header'>
          <Link onClick={props.click} to='/biography'>
            <img style={{ width: "100%" }} src={logo} alt='' />
          </Link>

          <hr></hr>
        </div>
        <ul>
          {routes.map((r) => (
            <li key={r.route}>
              <Link onClick={props.click} to={r.route}>
                <Translator path={r.label} />
              </Link>
            </li>
          ))}
        </ul>
        <Socials />

        <div className='i18n'>
          <I18n />
        </div>
        <div className='mechelen-logo'>
          <img src={MechelenLogo} />
        </div>
      </nav>
    </div>
  );
};

export default sideDrawer;
