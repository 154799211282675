import React from "react";
import Youtube from "../../components/Youtube/Youtube";
import "./MediaStyles.scss";
import useContentful from "../../hooks/useContentful";
import { getYoutube } from "../../contentful";
import Grid from "../../components/Grid/Grid";
import Translator from "../../components/i18n/Translator";
import TitleBar from "../../components/TitleBar";

const Media = () => {
  const videos = useContentful(getYoutube).content;

  return (
    <div className='view'>
      <TitleBar title={<Translator path='Media' />} />

      <div className='media-page '>
        <Grid columns={3}>
          {videos.map((v) => (
            <Youtube key={v.fields.id} title={v.fields.title} videoId={v.fields.id} />
          ))}
        </Grid>
      </div>
    </div>
  );
};

export default Media;
