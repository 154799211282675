import React from "react";
import I18n from "../i18n/i18n";
import logo from "../../assets/logo.jpg";
import Mechelenlogo from "../../assets/imgs/Mechelen_logo.svg";
import { Link } from "react-router-dom";

import "./Toolbar.scss";
import DrawerToggleButton from "../DrawerToggleButton/DrawerToggleButton";
import Menu from "../Menu/Menu";

const toolbar = (props) => (
  <header className='toolbar'>
    <nav className='toolbar__navigation'>
      <div className='toolbar__toggle-button'>
        <DrawerToggleButton click={props.drawerClickHandler} />
      </div>
      <div className='toolbar__logo'>
        <Link to='/biography'>
          <img src={logo} alt='logo' />
        </Link>
      </div>
      <img className='toolbar__logo__mechelen' alt='logo' src={Mechelenlogo} />
      <Menu />

      <div className='spacer' />
      <div className='toolbar_navigation-items'>
        <div>
          <I18n />
        </div>
      </div>
    </nav>
  </header>
);

export default toolbar;
