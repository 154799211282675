import React, { useState, useEffect } from "react";
import "./LPMStyles.scss";
import { getAboutPageFr, getAboutPageEng, getAboutPageNl } from "../../contentful";
import TitleBar from "../../components/TitleBar";
import groupPic from "../../assets/imgs/Group.jpg";
import marked from "marked";

const Biog = () => {
  const [text, setText] = useState({});

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });

    const switchedLang = window.localStorage.getItem("lang");

    if (switchedLang === "en-US") {
      getAboutPageEng().then((data) => setText(data?.[0]?.fields));
    }
    if (switchedLang === "fr-FR") {
      getAboutPageFr().then((data) => setText(data?.[0]?.fields));
    }
    if (switchedLang === "nl-NL") {
      getAboutPageNl().then((data) => setText(data?.[0]?.fields));
    }
  }, []);

  return (
    <div className='view lpm'>
      <div className='lpm__image'>
        <img className src={groupPic} alt='' />
      </div>

      <div>
        {text?.text1 && (
          <section className='biog-page-text lpm__text' dangerouslySetInnerHTML={{ __html: marked(text?.text1) }} />
        )}
      </div>

      <hr />
    </div>
  );
};

export default Biog;
