import React from "react";
import "./AgendaStyles.scss";
import useContentful from "../../hooks/useContentful";
import { getAgenda } from "../../contentful";
import marked from "marked";
import Translator from "../../components/i18n/Translator";
import TitleBar from "../../components/TitleBar";

const Agenda = () => {
  const agenda = useContentful(getAgenda)?.content;
  var switchedLang = window.localStorage.getItem("lang");
  const dateOptions = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  };

  const onlyFuture = (item) => {
    const today = new Date();
    return new Date(item.fields.date) > today;
  };

  const getDesc = (fields) => {
    switch (switchedLang) {
      case "en-US":
        return fields.descriptionEn;
      case "fr-FR":
        return fields.descriptionFr;
      case "nl-NL":
        return fields.descriptionNl;
      default:
        return "";
    }
  };

  return (
    <div className='view'>
      <TitleBar title={<Translator path='Agenda' />} />

      {agenda.filter(onlyFuture).map(({ fields }, i) => {
        const date = new Date(fields.date);
        const time = date.toLocaleTimeString([], {
          hour: "2-digit",
          minute: "2-digit",
        });
        const dateString = date.toLocaleDateString(switchedLang, dateOptions);
        return (
          <div key={fields.title + i} className='agenda__item'>
            <div>
              <h3 className='agenda__date'>{dateString}</h3>
              <h4 className='agenda__time'>{time}</h4>
              <span className='agenda__title'>{fields.title}</span>
              <section className='agenda__desc' dangerouslySetInnerHTML={{ __html: marked(getDesc(fields)) }} />
              {fields.festivalLink && (
                <a href={fields.festivalLink} target='_blank' className='link-cta link-cta--dark'>
                  <Translator path='Tickets' />
                </a>
              )}
            </div>
            {fields.image && (
              <div className='agenda__image'>
                <img src={fields.image.fields.file.url} />
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default Agenda;
