import React from "react";
import useContentful from "../../hooks/useContentful";
import { getRecordings } from "../../contentful";
import RecordingCard from "../../components/RecordingCard/RecordingCard";
import TitleBar from "../../components/TitleBar";
import Translator from "../../components/i18n/Translator";
const Recordings = () => {
  const recordings = useContentful(getRecordings)?.content;
  console.log(recordings);
  return (
    <div className='view'>
      <TitleBar title={<Translator path='Recordings' />} />

      {recordings.map((r) => (
        <RecordingCard key={r.fields.title} recording={r.fields} />
      ))}
    </div>
  );
};

export default Recordings;
