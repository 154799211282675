import React from "react";
import {
  TwitterShareButton,
  TwitterIcon,
  LinkedinShareButton,
  LinkedinIcon,
  FacebookIcon,
  FacebookShareButton,
} from "react-share";

const TitleBar = (props) => {
  return (
    <div className='title-bar'>
      <div>
        <h1>{props.title}</h1>
      </div>
      <div className='title-bar__socials'>
        <small>share on: </small>

        <div id='social'>
          <TwitterShareButton url={window.location} children={<TwitterIcon size={24} />} />
          <LinkedinShareButton url={window.location} children={<LinkedinIcon size={24} />} />
          <FacebookShareButton url={window.location} children={<FacebookIcon size={24} />} />
        </div>
      </div>
    </div>
  );
};

export default TitleBar;
