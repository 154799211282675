import React, { useState, useEffect } from "react";
import { getProgrammesEng } from "../../contentful";
import TitleBar from "../TitleBar";
import Translator from "../i18n/Translator";
import "./ProgrammesStyles.scss";
import ProgrammeItem from "./ProgrammeItem";
const Projects = () => {
  const [projects, setProjects] = useState([]);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    getProgrammesEng().then((data) => setProjects(data));
  }, []);

  return (
    <div className='view'>
      <TitleBar title={<Translator path='Programmes' />} />
      {projects.map((project) => {
        return <ProgrammeItem programme={project.fields} />;
      })}
    </div>
  );
};

export default Projects;
