import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { getProgrammeDetailsEng } from "../../contentful";
import marked from "marked";
import Grid from "../../components/Grid/Grid";
import Youtube from "../../components/Youtube/Youtube";
import "./ProgrammeDetailsStyles.scss";
const ProgrammeDetails = () => {
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const [image, setImage] = useState("");
  const [videos, setVideos] = useState([]);
  const { navLink } = useParams();
  var switchedLang = window.localStorage.getItem("lang") || "nl-NL";

  useEffect(() => {
    if (switchedLang === "en-US") {
      getProgrammeDetailsEng(navLink).then((data) => {
        setContent(data?.[0]?.fields.content);
        setTitle(data?.[0]?.fields.title);
        setImage(data?.[0]?.fields.mainImage.fields.file.url);
        setVideos(data?.[0]?.fields.videos);
      });
    }
    if (switchedLang === "fr-FR") {
      getProgrammeDetailsEng(navLink).then((data) => {
        setContent(data?.[0]?.fields.contentFr);
        setTitle(data?.[0]?.fields.titleFr);
        setImage(data?.[0]?.fields.mainImage.fields.file.url);
        setVideos(data?.[0]?.fields.videos);
      });
    }
    if (switchedLang === "nl-NL") {
      getProgrammeDetailsEng(navLink).then((data) => {
        setContent(data?.[0]?.fields.contentNl);
        setTitle(data?.[0]?.fields.titleNl);
        setImage(data?.[0]?.fields.mainImage.fields.file.url);
        setVideos(data?.[0]?.fields.videos);
      });
    }
  }, [switchedLang, navLink]);
  return (
    <div className='programme-details'>
      <div className='programme-details__image'>
        <img src={image} />
        <h1 className='programme-details__title'>{title}</h1>
      </div>
      {content && (
        <section className='programme-details__content' dangerouslySetInnerHTML={{ __html: marked(content) }} />
      )}

      <Grid columns={2}>
        {videos?.length &&
          videos.map((v) => <Youtube key={v.fields.id} title={v.fields.title} videoId={v.fields.id} />)}
      </Grid>
    </div>
  );
};

export default ProgrammeDetails;
