import React from "react";
import { routes } from "../SideDrawer/SideDrawer";
import { NavLink } from "react-router-dom";
import Translator from "../i18n/Translator";
import "./MenuStyles.scss";

const Menu = () => {
  return (
    <div className='top-menu'>
      <ul className='top-menu__nav'>
        {routes.map((r) => (
          <li key={r.route}>
            <NavLink activeClassName='top-menu__link--active' className='top-menu__link' to={r.route}>
              <Translator path={r.label} />
            </NavLink>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Menu;
