export default {
  translations: {
    Title: "English",
    About: "Le Pavillon de Musique",
    Programmes: "Programmes",
    Recordings: "Recordings",
    Media: "Media",
    Contact: "Contact",
    ContactText: "We would love to hear from you",
    Agenda: "Agenda",
    RecordingInfo: "Violin concerti H.-J. De Croes. Coming soon!",
    FormText: "Subscribe to our newsletter",
    FormName: "Name and surname",
    FormEmail: "Email",
    PrivacyAgreed1: "I agree to the ",
    PrivacyAgreed2: " privacy policy (GDPR)",
    FormButton: "Register",
    Privacy: "Click here to read our privacy policy",
    Close: "close",
    MoreInfo: "More info",
    Tickets: "Tickets",
  },
};
